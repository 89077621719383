// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*export const environment = {
  production: false ,
  firebaseConfig : {
    apiKey: "AIzaSyB3OOrGA02xPHFPEiwPZxI3UY4dmj6s4gE",
    authDomain: "visualshop-1555400904211.firebaseapp.com",
    databaseURL: "https://visualshop-1555400904211.firebaseio.com",
    projectId: "visualshop-1555400904211",
    storageBucket: "visualshop-1555400904211.appspot.com",
    messagingSenderId: "904328107192",
    appId: "1:904328107192:web:543892cf47a1a95ebad04d",
    measurementId: "G-FX3EQRD4D7"
  }
};*/


export const environment = {
  production: true ,
  firebaseConfig : {
    apiKey: "AIzaSyBtP_WRwnCstpHVzyv3A9qlpFaxc7mroSk",
    authDomain: "visualmenu-c9c5b.firebaseapp.com",
    databaseURL: "https://visualmenu-c9c5b.firebaseio.com",
    projectId: "visualmenu-c9c5b",
    storageBucket: "visualmenu-c9c5b.appspot.com",
    messagingSenderId: "398037765613",
    appId: "1:398037765613:web:fc00a6f2c47533416f2be4",
    measurementId: "G-L5RTB4YJRJ"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
